
import _ from 'lodash'
// http://ariutta.github.io/svg-pan-zoom/demo/thumbnailViewer.html for minimap
// http://ariutta.github.io/svg-pan-zoom/demo/limit-pan.html limit pan
import { mapGetters } from 'vuex'
import ModalMixin from '@/mixins/modal'
import Modal from '@/components/elements/Modal'
import { findPixelByVendor } from '~/utils/pixels/helpers'
import { fbqEvent } from '~/utils/pixels/facebook'
import { tiktokEvent } from '~/utils/pixels/tiktok'
import { gtmDataLayer } from '~/utils/ga'
let Hammer = null
let svgPanZoom = null
if (process.browser) {
  Hammer = require('hammerjs')
  svgPanZoom = require('svg-pan-zoom')
}
export default {
  mixins: [ModalMixin],
  props: {
    event: {
      type: Object,
      required: true
    }
  },
  data: (vm) => ({
    labels: [],
    seats: [],
    underlay: [],
    tickets: [],
    loading: false,
    fit: false,
    initialized: false,
    rendered: false,
    showing: null,
    hint: {
      ticket: {
        currency: '-',
        info: '-',
        price: '-'
      },
      visible: false,
      selected: null,
      loading: true,
      position: {
        x: 0,
        y: 0
      },
      class: 'up'
    },
    beforePan(oldPan, newPan) {
      vm.hideHint(true)

      const gutterWidth = 100
      const gutterHeight = 100
      // Computed variables
      const sizes = this.getSizes()
      const leftLimit =
        -((sizes.viewBox.x + sizes.viewBox.width) * sizes.realZoom) +
        gutterWidth
      const rightLimit =
        sizes.width - gutterWidth - sizes.viewBox.x * sizes.realZoom
      const topLimit =
        -((sizes.viewBox.y + sizes.viewBox.height) * sizes.realZoom) +
        gutterHeight
      const bottomLimit =
        sizes.height - gutterHeight - sizes.viewBox.y * sizes.realZoom

      const customPan = {}
      customPan.x = Math.max(leftLimit, Math.min(rightLimit, newPan.x))
      customPan.y = Math.max(topLimit, Math.min(bottomLimit, newPan.y))

      return customPan
    },
    customEventsHandler: {
      haltEventListeners: [
        'touchstart',
        'touchend',
        'touchmove',
        'touchleave',
        'touchcancel'
      ],
      init(options) {
        const instance = options.instance
        let initialScale = 1
        let pannedX = 0
        let pannedY = 0

        // Init Hammer
        // Listen only for pointer and touch events
        this.hammer = Hammer(options.svgElement, {
          inputClass: Hammer.SUPPORT_POINTER_EVENTS
            ? Hammer.PointerEventInput
            : Hammer.TouchInput
        })

        // Enable pinch
        this.hammer.get('pinch').set({ enable: true })

        // Handle double tap
        this.hammer.on('doubletap', function (ev) {
          instance.zoomIn()
        })

        // Handle pan
        this.hammer.on('panstart panmove', function (ev) {
          // On pan start reset panned variables
          if (ev.type === 'panstart') {
            pannedX = 0
            pannedY = 0
          }

          // Pan only the difference
          instance.panBy({ x: ev.deltaX - pannedX, y: ev.deltaY - pannedY })
          pannedX = ev.deltaX
          pannedY = ev.deltaY
        })

        // Handle pinch
        this.hammer.on('pinchstart pinchmove', function (ev) {
          // On pinch start remember initial zoom
          if (ev.type === 'pinchstart') {
            initialScale = instance.getZoom()
            instance.zoomAtPoint(initialScale * ev.scale, {
              x: ev.center.x,
              y: ev.center.y
            })
          }

          instance.zoomAtPoint(initialScale * ev.scale, {
            x: ev.center.x,
            y: ev.center.y
          })
        })

        // Prevent moving the page on some devices when panning over SVG
        options.svgElement.addEventListener('touchmove', (e) => {
          e.preventDefault()
        })
      },

      destroy() {
        this.hammer.destroy()
      }
    }
  }),
  computed: {
    ...mapGetters({
      mobileLayout: 'mobileLayout',
      cartTickets: 'cart/content'
    }),
    isMobile() {
      return this.mobileLayout.includes(this.$mq)
    },
    shouldFit() {
      const mapDimensions = this.$refs.map.getBoundingClientRect()
      const largestX = this.seats.reduce(
        (acc, seat) => (acc = acc > seat.x ? acc : seat.x),
        0
      )
      const smallestX = this.seats.reduce(
        (acc, seat) => (acc = acc < seat.x ? acc : seat.x),
        0
      )
      const largestY = this.seats.reduce(
        (acc, seat) => (acc = acc > seat.y ? acc : seat.y),
        0
      )
      const smallestY = this.seats.reduce(
        (acc, seat) => (acc = acc < seat.y ? acc : seat.y),
        0
      )

      const xgl = Math.ceil(Math.abs(largestX - smallestX)) + 100
      const ygl = Math.ceil(Math.abs(largestY - smallestY)) + 100

      return xgl > mapDimensions.width || ygl > mapDimensions.height
    }
  },
  async mounted() {
    await this.fetchSeatmap()
    this.$nextTick(() => {
      this.init()
    })

    this.$root.$on('ticket:remove', (ticket) => {
      const seat = this.seats.find(
        (item) => parseInt(item.id) === parseInt(ticket.ticket_id)
      )

      if (seat) {
        seat.in_cart = false
      }
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    async fetchSeatmap() {
      const { labels, seats, underlay } = await this.$axios.$get(
        `/api/event/${this.event.id}/seatmap`
      )
      this.labels = labels || []
      this.seats = seats || []
      this.underlay = underlay || []
    },
    init() {
      this.svgpanzoom = svgPanZoom('#map', {
        zoomEnabled: true,
        dblClickZoomEnabled: false,
        fit: this.shouldFit,
        center: true,
        zoomScaleSensitivity: 0.35,
        maxZoom: 25,
        minZoom: 0.5,
        onPan: this.onPan,
        customEventsHandler: this.customEventsHandler,
        beforePan: this.beforePan
      })

      this.initialized = true
      this.onResize()
      this.svgpanzoom.setOnZoom((level) => {
        this.repostionHint()
      })
      this.rendered = true

      const panzoomviewport = this.$refs.map
        .querySelector('.svg-pan-zoom_viewport')
        .getBoundingClientRect()

      const svgviewport = this.$refs.map.getBoundingClientRect()
      if (
        svgviewport.width - panzoomviewport.width < 100 ||
        svgviewport.height - panzoomviewport.height < 100
      ) {
        this.zoomOut()
      }

      window.addEventListener('resize', this.onResize)
    },
    zoomIn() {
      this.svgpanzoom.zoomIn()
    },
    zoomOut() {
      this.svgpanzoom.zoomOut()
    },
    zoomReset() {
      this.svgpanzoom.resetZoom()
      this.svgpanzoom.center()
    },
    onRemove() {
      if (this.loading) return

      const selected = this.hint.selected
      const id = this.seats[selected].id
      const _ticket = Object.values(this.cartTickets).find(
        (item) => String(item.ticket_id) === String(id)
      )

      this.loading = true
      this.$store
        .dispatch('cart/REMOVE_TICKET', {
          ticket_id: id
        })
        .then(() => {
          // eslint-disable-next-line vue/no-mutating-props
          this.seats[selected].in_cart = false

          gtmDataLayer({
            event: 'remove_from_cart',
            ecommerce: {
              currency: _ticket.currency,
              value: Number(_ticket.price),
              items: [
                {
                  item_id: String(_ticket.price_zone_id),
                  item_name: _ticket.event_name,
                  currency: _ticket.currency,
                  price: Number(_ticket.price),
                  quantity: 1
                }
              ]
            }
          })
        })
        .catch(async (error) => {
          const msg =
            error.response.data &&
            error.response.data.i18n &&
            this.$te(error.response.data.i18n)
          const errorMsg =
            error.response.data && error.response.data.message
              ? `<br>${error.response.data.message}`
              : ''
          this.$modal.show(
            Modal,
            {
              title: 'Oooops!',
              text: `Error occured!
                ${msg ? `<br>${this.$t(error.response.data.i18n)}` : errorMsg}
              `
            },
            {
              height: 'auto',
              width: this.modalWidthCalc(),
              adaptive: true,
              scrollable: true,
              clickToClose: false
            }
          )

          this.hideHint(true)
          await this.fetchSeatmap()
        })
        .finally(() => {
          this.loading = false
        })
    },
    onAdd() {
      if (this.loading) return

      const selected = this.hint.selected
      const id = this.seats[selected].id
      this.loading = true
      this.$store
        .dispatch('cart/ADD_SEAT', {
          qty: 1,
          ticket_id: this.seats[selected].id
        })
        .then((response) => {
          // eslint-disable-next-line vue/no-mutating-props
          this.seats[selected].in_cart = true
          const ticket = Object.values(this.cartTickets).find(
            (item) => String(item.ticket_id) === String(id)
          )

          gtmDataLayer({
            event: 'add_to_cart',
            ecommerce: {
              currency: this.event.currency,
              value: Number(ticket.price),
              items: [
                {
                  item_id: String(ticket.price_zone_id),
                  item_name: this.event.name,
                  currency: this.event.currency,
                  price: Number(ticket.price),
                  quantity: 1
                }
              ]
            }
          })

          findPixelByVendor(this.event.tracking, 'facebook').map((id) => {
            return fbqEvent(id, 'AddToCart', {
              content_name: this.event.name,
              content_category: 'Event',
              content_ids: [this.event.id],
              content_type: 'seat',
              value: ticket.price,
              currency: this.event.currency
            })
          })

          findPixelByVendor(this.event.tracking, 'TikTok').map((id) => {
            return tiktokEvent(id, 'AddToCart', {
              contents: [
                {
                  content_id: this.event.id,
                  content_name: this.event.name,
                  content_type: 'seat',
                  quantity: 1,
                  price: ticket.price
                }
              ],
              content_type: 'product',
              value: ticket.price,
              currency: this.event.currency
            })
          })
        })
        .catch(async (error) => {
          const msg =
            error.response.data &&
            error.response.data.i18n &&
            this.$te(error.response.data.i18n)
          const errorMsg =
            error.response.data && error.response.data.message
              ? `<br>${error.response.data.message}`
              : ''
          this.$modal.show(
            Modal,
            {
              title: 'Oooops!',
              text: `Error occured!
                ${msg ? `<br>${this.$t(error.response.data.i18n)}` : errorMsg}
              `
            },
            {
              height: 'auto',
              width: this.modalWidthCalc(),
              adaptive: true,
              scrollable: true,
              clickToClose: false
            }
          )

          this.hideHint(true)
          await this.fetchSeatmap()
        })
        .finally(() => {
          this.loading = false
        })
    },

    hintPosition(target) {
      const mapBounds = this.$refs.map.getBoundingClientRect()
      const bottomOfScreen = window.pageYOffset + window.innerHeight
      const topOfScreen = window.pageYOffset
      const seatBounds = target.getBoundingClientRect()
      const hintHeigth = this.isMobile ? 175 : 115
      const hintWidth = 300
      const offsetY = 12
      const offsetX = 15

      const bottom =
        bottomOfScreen < mapBounds.bottom ? mapBounds.bottom : bottomOfScreen

      const top = topOfScreen + (mapBounds.top > 0 ? mapBounds.top : 0)

      const center = {
        x: mapBounds.width / 2 + mapBounds.left,
        y: (bottom + top) / 2
      }

      const result = {
        x: 0,
        y: 0,
        class: null
      }

      /**
       * This part is complicated. There are several positions to detect. The map itself looks like this:
       * ***************
       * 1 |       | 6 *
       * - |       | - *
       *   |   4   |   *
       * 2 |-------| 7 *
       *   |       |   *
       * - |   5   | - *
       * 3 |       | 8 *
       * ***************
       * 1 - top left
       * 2 - left
       * 3 - bottom left
       * 4 - top
       * 5 - bottom
       * 6 - top right
       * 7 - right
       * 8 - bottom right
       */

      let side = false

      if (seatBounds.x < hintWidth / 2) {
        result.class = 'left'
        side = true
      } else if (seatBounds.x > mapBounds.right - hintWidth / 2) {
        result.class = 'right'
        side = true
      }

      if (side) {
        if (seatBounds.y < mapBounds.top + hintHeigth / 2) {
          result.class += ' up'
        } else if (seatBounds.y >= mapBounds.bottom - hintHeigth / 2) {
          result.class += ' down'
        }
      } else if (seatBounds.y < center.y) {
        result.class = 'up'
      } else {
        result.class = 'down'
      }

      switch (result.class) {
        case 'left up':
          result.y = seatBounds.y - offsetY + Math.floor(seatBounds.height / 2)
          result.x = seatBounds.right + seatBounds.width / 2 + offsetX
          break

        case 'left':
          result.y =
            seatBounds.y -
            offsetY +
            Math.floor(seatBounds.height / 2) -
            Math.ceil(hintHeigth / 2)
          result.x = seatBounds.right + seatBounds.width / 2 + offsetX
          break

        case 'left down':
          result.y =
            seatBounds.y -
            offsetY +
            Math.floor(seatBounds.height / 2) -
            hintHeigth
          result.x = seatBounds.right + seatBounds.width / 2 + offsetX
          break

        case 'up':
          result.y = seatBounds.y + seatBounds.height
          result.x =
            seatBounds.left - Math.floor(hintWidth / 2) + seatBounds.width / 2
          break

        case 'down':
          result.y = seatBounds.y - hintHeigth - 30
          result.x =
            seatBounds.left - Math.floor(hintWidth / 2) + seatBounds.width / 2
          break

        case 'right up':
          result.y = seatBounds.y - offsetY + Math.floor(seatBounds.height / 2)
          result.x =
            seatBounds.left - hintWidth - seatBounds.width / 2 - offsetX
          break

        case 'right':
          result.y =
            seatBounds.y -
            offsetY +
            Math.floor(seatBounds.height / 2) -
            Math.ceil(hintHeigth / 2)
          result.x =
            seatBounds.left - hintWidth - seatBounds.width / 2 - offsetX
          break

        case 'right down':
          result.y =
            seatBounds.y -
            offsetY +
            Math.floor(seatBounds.height / 2) -
            hintHeigth
          result.x =
            seatBounds.left - hintWidth - seatBounds.width / 2 - offsetX
          break
      }

      result.class = result.class.split(' ').join('--')

      return result
    },

    repostionHint() {
      if (!this.showing) return

      const hintPosition = this.hintPosition(this.showing)

      this.hint.position.x = hintPosition.x + 'px'
      this.hint.position.y = hintPosition.y + 'px'
      this.hint.class = hintPosition.class
    },
    handleSeatClick(index) {
      if (index !== this.hint.selected) {
        this.hint.selected = index
      }

      if (this.seats[this.hint.selected].in_cart) {
        this.onRemove()
      } else {
        this.onAdd()
      }
    },
    handleHover(index) {
      if (this.hint.selected === index) return
      this.showing = this.$refs[`seat_${index}`][0]

      this.repostionHint()
      this.hint.loading = true
      this.hint.visible = true
      this.hint.selected = index

      this.handleHoverRequest(index)
    },
    handleHoverRequest: _.debounce(async function (index) {
      if (this.hint.selected !== index) return
      const id = this.seats[index].id

      if (this.tickets[id]) {
        this.hint.ticket = this.tickets[id]
        this.hint.loading = false
        return
      }

      try {
        const response = await this.$axios.$get(`/api/ticket/${id}/info`)
        this.tickets[id] = response
        this.hint.ticket = response
        this.hint.loading = false
      } catch (e) {
        console.log(e)
      }
    }, 500),
    hideHint(force = false, index = -1) {
      if (index === this.hint.selected || force) {
        this.hint.selected = null
        this.hint.visible = false
        this.showing = null
      }
    },
    onResize() {
      this.svgpanzoom.resize()
      this.svgpanzoom.center()
    },
    onPan() {
      this.hideHint(this.hint.selected)
    }
  }
}
